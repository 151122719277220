<template>
  <div class="gallery-viewer">
    <div class="bg-viewer" @click="closeGalleryViewer()"></div>
    <transition-group tag="div" class="img-box" :name="'slide-' + slideSide">
      <img
        v-show="currentImage === index"
        v-for="(image, index) in images" :key="index"
        :src="'gallery/' + image.src"
        :alt="image.alt"
        ref="image"
        :style="{
          'max-width': imgMaxWidth[index] + 'px',
          'max-height': imgMaxHeight[index] + 'px',
          'width': imgWidth[index],
          'height': imgHeight[index]}"
      >
    </transition-group>
    <div class="close-btn" @click="closeGalleryViewer()">&#10006;</div>
    <div
      v-if="images.length > 1"
      class="left-arrow"
      @click="prevImage()"
    >&#8249;</div>
    <div
      v-if="images.length > 1"
      class="right-arrow"
      @click="nextImage()"
    >&#8250;</div>
  </div>
</template>

<script>
export default {
  name: 'GalleryViewer',
  props: ['images', 'clickedImage', 'category'],
  data() {
    return {
      currentImage: null,
      slideSide: null,
      imgMaxWidth: [],
      imgMaxHeight: [],
      imgWidth: [],
      imgHeight: []
    }
  },
  methods: {
    closeGalleryViewer () {
      this.$emit('closeGalleryViewer', false)
    },
    setCurrentImage (index) {
      this.currentImage = index
    },
    countImgMaxSize () {
      for (let i = 0; i < this.images.length; i++) {
        this.imgMaxWidth.push(this.$refs.image[i].width)
        this.imgMaxHeight.push(this.$refs.image[i].height)
      }
    },
    countImgSize () {
      this.imgHeight = []
      this.imgWidth = []
      if (window.innerWidth > window.innerHeight) {
        for (let i = 0; i < this.images.length; i++) {
          if (this.imgMaxWidth[i] > this.imgMaxHeight[i]) {
            this.imgHeight.push('auto')
            this.imgWidth.push(window.innerWidth - window.innerWidth * 0.05 + 'px')
          }
          else {
            this.imgHeight.push(window.innerHeight - window.innerHeight * 0.05 + 'px')
            this.imgWidth.push('auto')
          }
        }
      }
      else {
        for (let i = 0; i < this.images.length; i++) {
          if (this.imgMaxWidth[i] > this.imgMaxHeight[i]) {
            this.imgHeight.push('auto')
            this.imgWidth.push(window.innerWidth - window.innerWidth * 0.05 + 'px')
          }
          else {
            this.imgHeight.push(window.innerHeight - window.innerHeight * 0.05 + 'px')
            this.imgWidth.push('auto')
          }
        }
      }
    },
    nextImage () {
      this.slideSide = 'right'
      this.currentImage === this.images.length - 1 ? this.currentImage = 0 : this.currentImage++
    },
    prevImage () {
      this.slideSide = 'left'
      this.currentImage === 0 ? this.currentImage = this.images.length - 1 : this.currentImage--
    }
  },
  created () {
    this.setCurrentImage(this.clickedImage)
    window.addEventListener('resize', this.countImgSize)
  },
  mounted () {
    this.countImgMaxSize()
    this.countImgSize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.countImgSize)
  }
}
</script>

<style scoped lang="scss">

.gallery-viewer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  .bg-viewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#eee,.7);
    z-index: 1500;
  }
  .img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    z-index: 1501;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .left-arrow, .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    font-size: 14rem;
    color: #555;
    transition: all .25s;
    z-index: 1501;
  }
  .left-arrow {
    left: 5%;
  }
  .right-arrow {
    right: 5%;
  }
  .close-btn {
    position: absolute;
    top: 20px;
    right: 3%;
    display: block;
    min-width: 43px;
    width: 43px;
    height: 58px;
    font-size: 5rem;
    color: #555;
    opacity: .5;
    transition: all .25s;
    z-index: 1501;
    @media screen and (min-width: 960px) {
      &:hover {
        color: #EB7054;
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}
.slide-right-enter-active, .slide-right-leave-active {
  transition: all .5s ease-in;
  position: absolute;
}
.slide-right-enter {
  transform: translateX(100vw);
}
.slide-right-leave-active {
  transform: translateX(-100vw);
}
.slide-left-enter-active, .slide-left-leave-active {
  transition: all .5s ease-in;
  position: absolute;
}
.slide-left-enter {
  transform: translateX(-100vw);
}
.slide-left-leave-active {
  transform: translateX(100vw);
}

@media only screen and (min-width: 996px) {
	.gallery-viewer {
    .img-box {
      margin: 0 25px;
    }
    .left-arrow, .right-arrow {
      opacity: .5;
      &:hover {
        cursor: pointer;
        opacity: 1;
        color: #EB7054;
      }
    }
    .left-arrow {
      left: 3%;
    }
    .right-arrow {
      right: 3%;
    }
  }
}

</style>
